import axios from 'axios';
import { Subject } from 'rxjs';

class Api {
  constructor() {
    this.token = sessionStorage.getItem('token');
    this.root = process.env.API_ROOT;
    this.errors = new Subject();
  }

  getAuthOptions() {
    return {
      headers: {
        Authorization: `JWT ${this.token}`,
      },
    };
  }

  handleError(error) {
    const { response } = error;
    this.errors.next(error);
    switch (response.status) {
      case 401:
        this.logout();
        break;
      default:
    }
  }

  login(data) {
    return axios.post(`${this.root}/er-doc-login/`, data)
      .then((response) => {
        const { token } = response.data;
        sessionStorage.setItem('token', token);
        this.token = token;
      })
      .catch((error) => this.handleError(error));
  }

  logout() {
    sessionStorage.removeItem('token');
    this.token = null;
  }

  isAuthorized() {
    return this.token !== null;
  }

  getFacilities() {
    return axios.get(`${this.root}/facilities/`)
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getSessionData() {
    return axios
      .get(`${this.root}/session-data/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  savePatient(patient) {
    return axios.post(`${this.root}/patients/`, patient, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  saveSurvey(survey) {
    return axios.post(`${this.root}/survey/`, survey, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  listPatients() {
    return axios.get(`${this.root}/patients/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  saveUsageReport(resourceName) {
    const usageReport = {
      url: window.location.href,
      resource_name: resourceName,
    };
    return axios.post(`${this.root}/usage-report/`, usageReport, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  saveHeartScore(score) {
    return axios.post(`${this.root}/heart-score/`, { heart_score: score }, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }
}

export default new Api();
