import React, {
  Suspense, lazy, useState, useEffect,
} from 'react';
import {
  HashRouter,
  Route,
  Redirect,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import Api from '../Api';

const Login = lazy(() => import('../Login/Login'));
const Dashboard = lazy(() => import('../Dashboard/Dashboard'));

const theme = createMuiTheme({
  palette: {
    background: {
      default: grey[50],
    },
    common: {
      white: '#fff',
    },
    primary: blue,
    text: {
      primary: blue[900],
      secondary: blue[500],
    },
  },
  spacing: 8,
});

const BaseRoute = ({ location }) => {
  const [, pilotPathname] = location.pathname.split('/');
  const path = Api.isAuthorized()
    ? `/${pilotPathname}/dashboard`
    : `/${pilotPathname}/login`;
  return <Redirect to={path} />;
};
BaseRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const LoginRoute = ({ location, study }) => {
  const [, pilotPathname] = location.pathname.split('/');
  return Api.isAuthorized()
    ? (<Redirect to={`/${pilotPathname}/dashboard`} />)
    : (<Login study={study} />);
};
LoginRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  study: PropTypes.string.isRequired,
};

const DashboardRoute = ({ location }) => {
  const [, pilotPathname] = location.pathname.split('/');
  return Api.isAuthorized()
    ? (<Dashboard />)
    : (<Redirect to={`/${pilotPathname}/login`} />);
};
DashboardRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const PilotRoute = ({ match }) => {
  const { url } = useRouteMatch();
  const { study } = match.params; // TODO: study is no longer used for anything, so remove it
  return (
    <Switch>
      <Route exact path={`${url}/`} component={BaseRoute} />
      <Route
        path={`${url}/login`}
        render={({ location }) => <LoginRoute location={location} study={study.toLowerCase()} />}
      />
      <Route path={`${url}/dashboard`} component={DashboardRoute} />
    </Switch>
  );
};
PilotRoute.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      study: PropTypes.string,
    }),
  }).isRequired,
};

const App = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  useEffect(() => {
    const { unsubscribe } = Api.errors.subscribe(
      (error) => {
        setSnackbarMessage(error.message);
        setSnackbarOpen(true);
      },
    );
    return () => unsubscribe();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<span>Loading...</span>}>
        <HashRouter>
          <Switch>
            <Route path="/pilot-:study" component={PilotRoute} />
          </Switch>
        </HashRouter>
        <Snackbar
          autoHideDuration={5000}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            elevation={6}
            severity="error"
            variant="filled"
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
